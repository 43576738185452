<template>
  <div
    style="
      position: relative;
      color: transparent;
      background-color: transparent;
      z-index: 2;
    "
  >
  <v-card
      v-if="timeDuration && $vuetify.breakpoint.mobile"
      :style="{ 'padding-top': $vuetify.breakpoint.mobile ? '0' : '1vh' }"
      style="
        height: 30px; position: absolute; top: 2vh; right: 2vh; z-index: 1
      "
      class="px-1"
    >
      <v-card-title
        style="display: flex; justify-content: center; align-items: center"
        :style="{
          'font-size': $vuetify.breakpoint.mobile ? '0.6em' : 'calc(100%)',
        }"
        class="ma-0 pa-0"
      >
        {{ $t("presentation.time") + this.timeDuration + "s" }}
      </v-card-title>
    </v-card>
    <v-btn
      elevation="1"
      style="height: 2em; position: absolute; top: 2vh; left: 2vh; z-index: 1"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
      @click="closePresentation"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn
      elevation="1"
      style="height: 2em; position: absolute; top: 2vh; right: 2vh; z-index: 1"
      :style="{'display': $vuetify.breakpoint.mobile ? 'none' : '',}"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
      @click="toggleSideBar(); updateScale()"
    >
      <v-icon>{{ sideBarIcon }}</v-icon>
    </v-btn>

    <v-btn
    text
      elevation="1"
      large
      :class="$vuetify.breakpoint.mobile ? 'landscapeControls' : ''"
      style="display: none; height: 40px; position: absolute; top: 86vh; right: 4vh; z-index: 3"
      @click="switchItem(1)"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn
    text
    large
      elevation="1"
      :class="$vuetify.breakpoint.mobile ? 'landscapeControls' : ''"
      style="display: none; height: 40px; position: absolute; top: 86vh; left: 4vh; z-index: 3"
      @click="switchItem(-1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("presentation", ["loading", "sideBarHidden", "timeDuration"]),
    sideBarIcon() {
      return this.sideBarHidden
        ? "mdi-arrow-collapse-left"
        : "mdi-arrow-collapse-right";
    },
  },
  methods: {
    ...mapActions("presentation", ["toggleSideBar", "closePresentation", "switchItem"]),
    ...mapActions("draw", ["updateScale"]),
  },
};
</script>

<style scoped>
@media (orientation: portrait) {
#hideSideBarButton {
  display: none;
}
}
@media (orientation: landscape) {
.landscapeControls {
  display: block !important;
}
}
</style>
